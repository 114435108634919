import React, { useState } from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Helpers
import { HEADERS } from '@helpers/javascript/config/CONSTANTS'

const ProductForm = ({deactivate, submitProduct}) => {
  const [errors, setErrors] = useState(null)
  const [loading, setLoading] = useState(false)
  const [product, setProduct] = useState({
    brand:               "",
    ref_product_brand:   "",
    EAN13:               "",
    ref_product_distrib: "",
    designation:         "",
    purchase_price:      "",
    sale_price:          "",
    unit:                "",
    product_url:         "",
    stock_total:         "",
    stock_low_level:     "",
    valid: function() {
      return(this.sale_price != "" && this.designation != "")
    }
  })

  const onChange = (e, attribute) => {
    e.preventDefault()
    setErrors(null)
    setProduct({...product, [attribute]: e.target.value})
  }

  const onSubmit = e => {
    e.preventDefault()
    setLoading(true)
    if (!product.purchase_price) product.purchase_price = 0
    fetch('/quotes/form/create_product.json',{
      method:      'POST',
      headers:     HEADERS,
      credentials: 'same-origin',
      body:        JSON.stringify({product: product})
    })
    .then(response => response.json())
    .then(product => {
      setLoading(false)
      setLoading(null)
      product.errors ? setErrors(product.errors) : submitProduct(product)
    })
  }

  const productInput = (label, attribute, number = false) =>
    <div className="form-group">
      <label>
        {label} {['designation', 'sale_price'].includes(attribute) && '*'}
      </label>

      <input
        className="form-control"
        onChange={e => onChange(e, attribute)}
        value={product[attribute]}
        type={number ? "number" : "text"}
        step={number ? "0.01" : ""}
        min={number ? "0.01" : ""}
      />
    </div>

  return(
    <div className="quotes--form-full-row pr-3 pl-3">

      <h5>{I18n.t("products.new")}</h5>

      {productInput(I18n.t("products.designation"), "designation")}

      <div className="flexbox-space-between double">
        {productInput(I18n.t("products.brand"), "brand")}

        {productInput(I18n.t("products.ref_product_brand"), "ref_product_brand")}
      </div>

      <div className="flexbox-space-between double">
        {productInput(I18n.t("products.ref_product_distributor"), "ref_product_distrib")}

        {productInput("EAN13", "EAN13")}
      </div>

      <div className="flexbox-space-between">
        {productInput(I18n.t("products.purchase_price"), "purchase_price", true)}

        {productInput(I18n.t("products.sale_price"), "sale_price", true)}

        {productInput(I18n.t("products.unit"), "unit")}
      </div>

      <div className="flexbox-space-between double">
        {productInput(I18n.t("products.stock_set_initial"), "stock_total")}

        {productInput(I18n.t("products.stock_set_initial_low_level"), "stock_low_level")}
      </div>

      {productInput(I18n.t("products.product_url"), "product_url")}

      { errors &&
        <ul className="notice alert">
          {errors.map(error => <li key={error}>{error}</li>)}
        </ul>
      }
      <div className="d-flex">
        <div className="ml-auto btn-end">
          <button
            className="btn btn-outline-default btn-cancel-lg small"
            onClick={deactivate}
          >
            {I18n.t("button.cancel")}
          </button>

          <button
            className="btn btn-primary ml-2 small"
            onClick={onSubmit}
            disabled={loading}
          >
            {I18n.t("products.add")}
          </button>
        </div>
      </div>
    </div>
  )
}

export default ProductForm
