import React, { useState } from 'react'

import StorehouseSelect from '../purchase_orders/storehouse_select'

// Helpers
import { getRequest, pathname } from '@react/helpers/fetch'

const route = "/stocks/:id"
const StockSelect = props => {
  const {
    prefix,
    storehouses,
    prompt,
    product
  } = props

  const [stocks, setStocks] = useState(null)

  const onChange = storehouse => {
    const path   = route.replace(":id", product.id)
    const params = {
      storehouse_type: storehouse.storehouse_type,
      storehouse_id:   storehouse.storehouse_id
    }
    const url = pathname({ path, params })
    getRequest(url).then(setStocks)
  }

  return(
    <>
      <StorehouseSelect
        prefix      = {prefix}
        storehouses = {storehouses}
        prompt      = {prompt}
        onChange    = {onChange}
        required
      />

      { stocks &&
        <div className="stock-display">
          { stocks.map(stock =>
            <div key={stock.id}>
              {stock.name}
              <span className="stock-value">
                { stock.quantity }
              </span>
            </div>
          )}

          { stocks.length == 0 &&
            <div>
              { I18n.t("products.currently_in_stock") }
              <span className="stock-value">
                0
              </span>
            </div>
          }
        </div>
      }
    </>
  )
}

export default StockSelect
