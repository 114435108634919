import React, { useState, useEffect, useRef } from 'react'
import { modalRequest, patchRequest }         from '../helpers/fetch'
import MonthPickr                             from '@shared/monthpickr'
import FullCalendar                           from '@fullcalendar/react' // must go before plugins

// plugins
import interactionPlugin      from "@fullcalendar/interaction"
import resourceTimelinePlugin from '@fullcalendar/resource-timeline'
import scrollGrid             from '@fullcalendar/scrollgrid'
import resource               from '@fullcalendar/resource'

// helpers
import { CALENDAR_VIEWS }       from '@helpers/javascript/calendar_helper'
import { pathname }             from '@helpers/javascript/javascript'
import { datetimeLocalInput }   from '@helpers/javascript/date_helpers'

export const Calendar = ({
  license,
  visibleMaterials,
  events,
  fetchEvents,
  currentDate,
  setCurrentDate,
  timeZone
 }) => {
  const [fetching, setFetching] = useState()
  const calendarRef = useRef(null);
 
  const handleSelect = e => {
    const endDate = new Date(e.end)
    const path = '/material_rentals/new'
    const params = {
      start_date: e.startStr,
      end_date:   new Date(endDate.setDate(endDate.getDate() - 1)).toDateString(),
    }

    params.material = e.resource._resource.id
    const url = pathname({ path, params })

    return modalRequest(url)
  }

  const handleEventClick = e => {
    const id = e.event._def.publicId
    const path = `/material_rentals/${id}`
    const url = pathname({ path })

    return modalRequest(url)
  }

  const handleDateChange = date => {
    if(fetching) clearTimeout(fetching)
    setCurrentDate(date)
    setFetching(setTimeout(() => { fetchEvents(date) }, 500))
  }

  const goToDate = date => {
    let calendarApi = calendarRef.current.getApi();
    calendarApi.gotoDate(moment(date).format('YYYY-MM-DDTHH:mm:ss'));
  }
  
  const handleEventChange = e => {
    const id = e.event._def.publicId
    const endDate = new Date(e.event.end)
    const path = `/material_rentals/${id}`
    const body = {
      start_date:  e.event.startStr,
      end_date:    new Date(endDate.setDate(endDate.getDate() - 1)).toDateString(),
      material_id: e.event._def.resourceIds[0]
    }
    const url = pathname({ path })
    patchRequest({ url, body }).then((res) => fetchEvents(res.start_date))
  }

  const renderEventContent = e => {
    return (
      <div>
        <b>{e.event._def.extendedProps.info.site}</b>
        <div className="mt-1 ">
          {e.event._def.extendedProps.info.start_date} - {e.event._def.extendedProps.info.end_date}
        </div>
      </div>
    )
  }

  useEffect(() => {
    const handleUpdateCalendar = event => {
      const date = event.detail.date;
      fetchEvents(date)
    }
    document.addEventListener('updateCalendar', handleUpdateCalendar)
  }, [])

 
  return (
    <div className='rental-calendar'>
      <div className='month-selector'>
        <MonthPickr onSelect = { goToDate } defaultDate = { currentDate } />
      </div>
      <FullCalendar
        ref                   = {calendarRef}
        schedulerLicenseKey   = {license}
        timeZone              = {timeZone}
        resourceOrder         = 'title'
        plugins               = {
          [
            scrollGrid,
            interactionPlugin,
            resourceTimelinePlugin,
            resource
          ]
        }
        initialView           = { CALENDAR_VIEWS.month }
        initialDate           = {currentDate}
        headerToolbar         = {{
          left:   `prev,next`,
          center: 'title',
          right:  ''
        }}
        weekNumberCalculation ="ISO"
        resourceAreaWidth     ='15%'
        events                = {events}
        resources             = {visibleMaterials}
        datesSet              = {e => handleDateChange(datetimeLocalInput(e.start))}
        select                = {handleSelect}
        eventResize           = {handleEventChange}
        eventDrop             = {handleEventChange}
        eventContent          = {renderEventContent}
        eventClick            = {handleEventClick}
        eventTimeFormat       = {{ meridiem: false, hour12: false }}
        locale                = {I18n.locale}
        dayMinWidth           = {150}
        editable
        selectable
        eventResourceEditable
        nowIndicator
        allDaySlot
      />

      <button 
        className="hidden" 
        id='calendar-refetch'
        onClick= {() => fetchEvents()}
      ></button>
    </div>
  )
}