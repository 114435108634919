// Modules
import React, { useState } from "react"

import { library }         from '@fortawesome/fontawesome-svg-core'
import { fas }             from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

library.add(fas)

// Components
import Combobox from "@shared/combobox"

const nameFor = (link, attribute) => `product[product_suppliers_attributes][${link.supplier.id}][${attribute}]`

const Suppliers = ({ suppliers, selected }) => {

  const [links, setLinks] = useState(selected)
  const [refresh, setRefresh] = useState(false)
  const createLink = supplier => {
    if (links.find(link => link.supplier.id == supplier.id)) {
      setRefresh(!refresh)
    } else {
      setLinks([...links, { supplier }])
    }
  }

  const destroyLink = link => {
    setLinks(links.map(l => l.supplier.id != link.supplier.id ? l : { ...link, destroy: true }))
  }

  return(
    <>
      { links.length > 0 &&
        <div className="form-group">
          { links.filter(l => !l.destroy).map(link =>
            <div className="d-grid g-t-col-3 gap-5" key={link.supplier.id}>
              <input
                name         = {nameFor(link, "supplier_id")}
                defaultValue = {link.supplier.id}
                hidden
              />

              <input
                name         = {nameFor(link, "id")}
                defaultValue = {link.id}
                hidden
              />

              <div onClick={() => destroyLink(link)} className="multiselect__item m-0 mb-2">
                {link.supplier.name} <FontAwesomeIcon icon="times"/>
              </div>

              <input
                name         = {nameFor(link, "reference")}
                className    = "form-control mb-2"
                placeholder  = {I18n.t("client_ledgers.table.ref") + ' ' + link.supplier.name}
                defaultValue = {link.reference || ""}
              />

              <input
                name         = {nameFor(link, "purchase_price")}
                type         = "number"
                step         = "0.01"
                className    = "form-control mb-2"
                placeholder  = {I18n.t("products.purchase_price")}
                defaultValue = {link.purchasePrice || ""}
              />
            </div>
          )}

          { links.filter(l => l.destroy && l.id).map(link =>
            <div className="d-none">
              <input name={nameFor(link, "id")}       defaultValue={link.id}/>
              <input name={nameFor(link, "_destroy")} defaultValue="1"/>
            </div>
          )}
        </div>
      }

      <div className="form-group">
        <Combobox
          options         = {suppliers}
          onSelectElement = {createLink}
          value           = {{}}
          prompt          = {I18n.t("suppliers.select_suppliers_for_product")}
        />
      </div>
    </>
  )
}

export default Suppliers
