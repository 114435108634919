import React, { useState, useEffect, useRef } from 'react'

// helpers
import { getRequest, pathname }       from '@helpers/javascript/javascript'

// components
import SmallCard                      from './smallCard'
import CalendarLeft                   from './calendar-left'
import CalendarRight                  from './calendar-right'
import TooltipPopup                   from './tooltip-popup'

const App = ({
  preferences,
  license,
  timeZone,
  absences,
  agencies = [],
  categories = [],
  partsStatusPermitted,
}) => {

  const didMountRef = useRef(false)

  const [calendar,      setCalendar]      = useState()
  const [currentDate,   setCurrentDate]   = useState()
  const [events,        setEvents]        = useState()
  const [openEvents,    setOpenEvents]    = useState()
  const [startDate,     setStartDate]     = useState()
  const [endDate,       setEndDate]       = useState()
  const [throttle,      setThrottle]      = useState()
  const [resources,     setResources]     = useState([])
  const [showSmallCard, setShowSmallCard] = useState(false)
  const [autoRefresh,   setAutoRefresh]   = useState(false)
  const [showPopup,     setShowPopup]     = useState(false)
  const [slotMinTime,   setSlotMinTime]   = useState(preferences.calendar_start)
  const [slotMaxTime,   setSlotMaxTime]   = useState(preferences.calendar_end)
  const [showWeekends,  setShowWeekends]  = useState(preferences.show_calendar_weekend)
  const [filter,        setFilter]        = useState({
    filterCalendar:    true,
    showOpen:          preferences.calendar_show_open,
    showReceivedParts: true,
    radius:            "2",
    text:              "",
    address:           "",
    period:            "",
    agencies:          agencies,
    agency:            "",
    categories:        categories,
    category:          ""
  })
  useEffect(() => {
    if (didMountRef.current) {
      if (throttle) clearTimeout(throttle)

      setThrottle(setTimeout(() => {
        fetchAllEvents()
        if (filter.showOpen) fetchOpenEvents()
      }, 500))
    } else {
      fetchResources()
      if (filter.showOpen) fetchOpenEvents()
      didMountRef.current = true
    }
  }, [filter])

  useEffect(() => {
    if (currentDate) {
      let url = new URL(window.location)
      url.searchParams.set('selected_date', currentDate.toISOString().split('T')[0]);
      history.replaceState(history.state, document.title, url.href)
    }
  }, [currentDate])

  useEffect(() => {
    if (!!autoRefresh) {
      const refreshInterval = 15000
      const refetchEvents = setInterval(() => fetchEvents({
        start:    startDate,
        end:      endDate,
        callback: setEvents
      }), refreshInterval)
      return () => clearInterval(refetchEvents);
    }
  }, [autoRefresh, startDate, endDate]);

  const fetchOpenEvents = () => {
    const path   = '/calendar/open_workorders'
    const params = filter
    const url    = pathname({ path, params })
    getRequest(url).then(setOpenEvents)
  }

  const fetchEvents = ({start, end, callback, ignoreFilter}) => {
    const path   = '/calendar/event_list'
    const params = ignoreFilter ? {} : filter

    if (start && end) {
      params.start = start
      params.end   = end
    }

    const url = pathname({ path, params })
    getRequest(url).then(callback)
  }

  const fetchAllEvents = () => {
    fetchEvents({
      start:        startDate,
      end:          endDate,
      callback:     setEvents,
      ignoreFilter: !filter.filterCalendar
    })
  }

  const fetchResources = () => {
    const path   = '/calendar/tech_list'
    const params = { new_calendar: true }
    const format = 'json'
    const url    = pathname({ path, params, format })
    getRequest(url).then(setResources)
  }

  return (
    <div className="calendar-grid">
      <CalendarLeft 
        calendar             = {calendar}
        resources            = {resources}
        setResources         = {setResources}
        currentDate          = {currentDate}
        showWeekends         = {showWeekends}
        setShowWeekends      = {setShowWeekends}
        slotMinTime          = {slotMinTime}
        slotMaxTime          = {slotMaxTime}
        setSlotMinTime       = {setSlotMinTime}
        setSlotMaxTime       = {setSlotMaxTime}
        autoRefresh          = {autoRefresh}
        setAutoRefresh       = {setAutoRefresh}
        filter               = {filter}
        setFilter            = {setFilter}
        setShowPopup         = {setShowPopup}
        partsStatusPermitted = {partsStatusPermitted}
      />

      <CalendarRight
        absences         = {absences}
        license          = {license}
        timeZone         = {timeZone}
        preferences      = {preferences}
        calendar         = {calendar}
        setCalendar      = {setCalendar}
        setCurrentDate   = {setCurrentDate}
        events           = {events}
        setEvents        = {setEvents}
        fetchEvents      = {fetchEvents}
        openEvents       = {openEvents}
        setOpenEvents    = {setOpenEvents}
        resources        = {resources}
        setStartDate     = {setStartDate}
        setEndDate       = {setEndDate}
        setShowSmallCard = {setShowSmallCard}
        slotMinTime      = {slotMinTime}
        slotMaxTime      = {slotMaxTime}
        showWeekends     = {showWeekends}
        filter           = {filter}
        setFilter        = {setFilter}
        setShowPopup     = {setShowPopup}
      />
      
      {showSmallCard && 
        preferences.show_calendar_popovers && 
        <SmallCard e={showSmallCard} />
      }

      <TooltipPopup showPopup={showPopup} />

      <button 
        className="hidden" 
        id='calendar-refetch' 
        onClick= {() => fetchAllEvents()}
      >
      </button>
    </div>
  ) 
}

export default App
