import React, { useState, useEffect } from 'react'
import { getRequest, modalRequest }   from '../helpers/fetch'
import { pathname }                   from '@helpers/javascript/javascript'
import { Calendar }                   from './calendar'
import { Filters }                    from './filters'
import { FontAwesomeIcon }            from '@fortawesome/react-fontawesome'

const Index = ({
  materials,
  license,
  timeZone,
  categories,
  date
}) => {
  const [selectedMaterials,  setSelectedMaterials]  = useState([])
  const [selectedCategories, setSelectedCategories] = useState([])
  const [visibleMaterials,   setVisibleMaterials]   = useState(materials)
  const [events,             setEvents]             = useState([])
  const [currentDate,        setCurrentDate]        = useState(date)

  const fetchEvents = date => {
    const path = '/material_rentals/event_list'
    let params = { selected_date: date || currentDate }
    if (selectedMaterials.length) { params.materials = selectedMaterials.map(mat => mat.id) }
    if (selectedCategories.length) { params.categories = selectedCategories.map(category => category.id) }

    const url = pathname({ path, params })
    getRequest(url).then(setEvents)
  }

  const addNew = () => {
    const path = '/material_rentals/new'
    const url = pathname({ path })
    modalRequest(url)
  }

  useEffect(() => {
    fetchEvents()
    if (selectedCategories.length && selectedMaterials.length) {
      const categoryIds = selectedCategories.map(cat => cat.id)
      const materialsWithCategory = selectedMaterials.filter(mat => categoryIds.includes(mat.category) )
      setVisibleMaterials(materialsWithCategory)
    } else if (selectedMaterials.length) {
      setVisibleMaterials(selectedMaterials)
    } else if (selectedCategories.length) {
      const categoryIds = selectedCategories.map(cat => cat.id)
      const materialsWithCategory = materials.filter(mat => categoryIds.includes(mat.category) )
      setVisibleMaterials(materialsWithCategory)
    } else {
      setVisibleMaterials(materials)
    }
  }, [selectedMaterials, selectedCategories])

  return (
    <div>
      <Calendar 
        materials         = { materials }
        timeZone          = { timeZone }
        license           = { license }
        visibleMaterials  = { visibleMaterials }
        events            = { events }
        fetchEvents       = { fetchEvents }
        currentDate       = { currentDate }
        setCurrentDate    = { setCurrentDate}
        />
      <div className='action-buttons-right'>
        <div 
          className = "btn btn-filter btn-sm btn-primary small mr-2"
          onClick   = { addNew }
        >
          <span><FontAwesomeIcon icon = "plus" /> { I18n.t("material_rentals.add") }</span>
        </div>
        <Filters 
          materials             = { materials }
          categories            = { categories }
          selectedMaterials     = { selectedMaterials }
          selectedCategories    = { selectedCategories }
          setSelectedMaterials  = { setSelectedMaterials }
          setSelectedCategories = { setSelectedCategories }
        />
      </div>
    </div>
  ) 
}

export default Index
